<script setup lang="ts">
import { ConfigProvider } from 'radix-vue'
import sourceSans3 from '~/assets/fonts/Source-Sans-3-normal.woff2'

import { useStaticSeoMeta } from '~/composables/staticSeoMeta'

const useIdFunction = () => useId()
const hasError = ref(false)

useCustomLoadingIndicator()
useVisitorStore()
useLastSearchStore()
useStaticSeoMeta()
useTrackStaticPages()

onErrorCaptured((error) => {
  console.error(error)
  hasError.value = true
  return true
})

useHead({
  htmlAttrs: {
    lang: 'en-US',
  },
  link: [
    {
      rel: 'preload',
      type: 'font/woff2',
      href: sourceSans3,
      as: 'font',
      crossorigin: '',
    },
  ],
})
</script>

<template>
  <!-- `useId` injection for Radix for preventing hydration mismatch issues in Nuxt 3 -->
  <!-- https://github.com/radix-vue/radix-vue/issues/577#issuecomment-1980983686 -->
  <ConfigProvider :use-id="useIdFunction">
    <div class="cs-page flex min-h-screen flex-col" :class="[`cs-page--${String($route.name)}`]">
      <NuxtLoadingIndicator
        color="#266EBF"
        :height="5"
      />
      <UiNotification v-if="hasError" variant="error">
        Oops! Something went wrong.
      </UiNotification>
      <AppHeader />
      <main id="main" class="flex grow flex-col">
        <slot />
      </main>
      <AppFooter />
    </div>
  </ConfigProvider>
</template>

<style>
/* View transitions */
::view-transition-old(root) {
  animation-duration: 0.15s;
}

::view-transition-new(root) {
  animation-duration: 0.15s;
}
</style>

import type { RouteLocationNormalized } from 'vue-router'

let initialized = false

export function useTrackStaticPages() {
  const { trigger } = useVastImpressions()
  if (import.meta.server)
    return

  if (initialized)
    return

  initialized = true

  const route = useRouter().currentRoute
  const dynamic = ['search', 'detail']

  watch(route, (to: RouteLocationNormalized) => {
    if (dynamic.includes(to.name as string))
      return

    const trackingData = getDataForRouteName(to.name as string)
    if (trackingData.uri === '')
      trackingData.uri = to.fullPath

    trigger({
      type: 'action',
      action: 'view',
      action_name: 'view_page',
      tracking_data: JSON.stringify({
        source_page: trackingData.source_page,
        source_element: trackingData.source_element,
      }),
      uri: trackingData.uri,
    })
  }, {
    immediate: true,
  })
}

function getDataForRouteName(routeName: unknown) {
  return typeof routeName === 'string'
    ? {
        source_page: routeName,
        source_element: routeName,
        uri: routeName,
      }
    : {
        source_page: 'unknown',
        source_element: 'unknown',
        uri: 'unknown',
      }
}

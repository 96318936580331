<script setup lang="ts">
const favorites = useFavoritesStore()
const homeLink = computed(() => {
  const range = useRoute().query.range as string
  const zip = useVisitorStore().zipCode
  return new UrlBuilder('home', { zip, range }).href()
})
</script>

<template>
  <header class="py-3 md:py-4" :class="{ '!bg-blue-light-10': $route.name === 'home' }">
    <p>
      <a
        href="#main"
        class="sr-only focus:not-sr-only focus:absolute focus:left-3 focus:top-3 focus:z-10 focus:rounded-lg focus:border focus:border-solid focus:border-blue-dark focus:bg-white focus:p-1 focus:px-8 focus:py-3 focus:font-semibold focus:text-blue-dark"
      >
        Skip to main content
      </a>
    </p>

    <UiContainer size="full" class="flex flex-wrap items-center justify-between gap-3">
      <NuxtLink
        :href="homeLink"
        class="hover:brightness-90 active:outline-none active:brightness-100"
        aria-label="CarStory Home"
      >
        <Icon name="carstory:carstory-logo" mode="svg" class="h-[33px] w-[120px]" />
      </NuxtLink>

      <UiTextSearch v-if="$route.name === 'search'" :zip-code="useVisitorStore().zipCode" class="order-1 mb-1 flex-1 basis-full md:order-none md:mb-0 md:max-w-[min(54%,458px)]" />

      <nav>
        <ul class="flex gap-4 font-semibold lg:gap-6">
          <li>
            <NuxtLink :to="{ name: 'ico' }" class="hover:underline">
              Sell Us Your Car®
            </NuxtLink>
          </li>
          <li>
            <!-- TODO: <NuxtLink :to="{ name: 'favorites' }" @click="$ga('ClickFavoritesPage', 'Header')"> -->
            <NuxtLink key="favorites.count" :to="{ name: 'saved-vehicles' }" class="hover:underline">
              Saved Vehicles ({{ favorites.count }})
            </NuxtLink>
          </li>
          <!-- <li>
            <NuxtLink :to="{ name: 'saved-vehicles' }" class="hover:underline">
              Saved Searches
              <template v-if="false">
                (0)
              </template>
            </NuxtLink>
          </li> -->
        </ul>
      </nav>
    </UiContainer>
  </header>
</template>

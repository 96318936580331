<script setup lang="ts">
import { tv } from 'tailwind-variants'

const currentYear = new Date().getFullYear()

const classes = tv({
  slots: {
    listItem: 'border-l border-solid border-l-white px-3.5 hover:underline',
    list2Item: 'hover:underline md:border-l md:border-solid md:border-l-white md:pl-3.5',
  },
})

const dialogSaveSearchOpened = ref(false)

function openModal() {
  dialogSaveSearchOpened.value = true
}

onMounted(() => {
  setInterval(() => {
    const open = 0
    if (!dialogSaveSearchOpened.value && open)
      dialogSaveSearchOpened.value = true
  }, 100)
})
</script>

<template>
  <footer class="bg-black py-8 font-semibold text-white">
    <UiContainer size="full">
      <div class="flex flex-col gap-6">
        <div class="flex flex-col items-start gap-6 md:flex-row md:items-center md:justify-between">
          <img src="~/assets/icons/carstory-logo-white.svg" alt="CarStory" loading="lazy" width="120" height="33">

          <ul class="flex leading-5">
            <li :class="classes().listItem({ class: 'border-l-0 pl-0' })">
              <NuxtLink :to="{ name: 'about' }" no-prefetch>
                About
              </NuxtLink>
            </li>
            <li :class="classes().listItem()">
              <a href="https://www.carstory.ai">Dealer Products</a>
            </li>
          </ul>

          <ul class="-ml-1 flex gap-3 md:ml-0">
            <li>
              <a
                href="https://www.facebook.com/DriveHappilyEverAfter"
                title="CarStory on Facebook"
                class="flex w-4 justify-center hover:drop-shadow-white"
              >
                <img src="~/assets/icons/social-facebook.svg" alt="Facebook logo" loading="lazy" class="size-4">
              </a>
            </li>
            <li>
              <a
                href="https://x.com/CarStory"
                title="CarStory on X"
                class="flex w-4 justify-center hover:drop-shadow-white"
              >
                <img src="~/assets/icons/social-x.svg" alt="X logo" loading="lazy" class="size-4">
              </a>
            </li>
          </ul>
        </div>

        <div class="h-px bg-white" />

        <ul class="flex flex-col gap-3 text-sm leading-5 md:flex-row">
          <li :class="classes().list2Item({ class: 'md:border-l-0 md:pl-0' })">
            <NuxtLink :to="{ name: 'terms-of-service' }" no-prefetch>
              Terms of Service
            </NuxtLink>
          </li>
          <li :class="classes().list2Item()">
            <NuxtLink :to="{ name: 'privacy-policy' }" no-prefetch>
              Privacy Policy
            </NuxtLink>
          </li>
          <li :class="classes().list2Item()">
            <a
              href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/e9963ac5-c242-4277-94e6-325c7be09771/36346518-bd93-4e02-9f52-902d7d2a43f9.html"
            >Do Not Sell My Information</a>
          </li>

          <li :class="classes().list2Item()">
            <NuxtLink :to="{ name: 'sitemap' }" no-prefetch external external-rel-attribute="">
              Sitemap
            </NuxtLink>
          </li>
          <li :class="classes().list2Item()">
            <a href="#" @click.prevent="openModal">Dealer Search</a>
            <client-only>
              <LazyDialogSearchDealers v-model="dialogSaveSearchOpened" />
            </client-only>
          </li>
        </ul>

        <p class="text-sm leading-5">
          © {{ currentYear }} CarStory and Vast. All rights reserved.
        </p>
        <p class="text-sm leading-5">
          This information is not guaranteed to be accurate.
          <NuxtLink :to="{ name: 'disclaimer' }" class="underline hover:no-underline" no-prefetch>
            View Disclaimer
          </NuxtLink>
        </p>
      </div>
    </UiContainer>
  </footer>
</template>

export function useCustomLoadingIndicator() {
  const { finish, start, isLoading, progress, set } = useLoadingIndicator()
  const progressHolder = ref(0)
  const loading = ref(false)

  watch(progress, (to) => {
    if (to !== 0 && to !== 100 && typeof to === 'number')
      progressHolder.value = to
  })

  watch(isLoading, (to) => {
    if (!to && loading.value === true) {
      console.log('settingTo', progressHolder.value)
      set(progressHolder.value + 30)
      start()
      set(progressHolder.value + 50)
    }
  })

  const nuxtApp = useNuxtApp()
  nuxtApp.hook('search-data:loading', () => {
    loading.value = true
    start()
  })
  nuxtApp.hook('search-data:loaded', () => {
    loading.value = false
    finish()
  })
}

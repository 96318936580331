export function useStaticSeoMeta() {
  const meta = {
    'home': {
      title: 'Used Cars & Trucks for Sale: Free CarStory Market Reports',
      description: 'Nationwide used car search with free CarStory Market Report on millions of used cars & trucks for sale - unbiased local marketplace data and insights to making better buying decisions',
      canonical: 'https://www.carstory.com/',
    },
    'about': {
      title: 'About us | CarStory',
      description: 'About us | CarStory',
      canonical: 'https://www.carstory.com/about',
    },
    'disclaimer': {
      title: 'Disclaimer | CarStory',
      description: 'Disclaimer | CarStory',
      canonical: 'https://www.carstory.com/disclaimer',
    },
    'sitemap': {
      title: 'Site Map | CarStory',
      description: 'Site Map | CarStory',
      canonical: 'https://www.carstory.com/sitemap',
    },
    'privacy-policy': {
      title: 'Privacy Policy | CarStory',
      description: 'Privacy Policy | CarStory',
      canonical: 'https://www.carstory.com/privacy-policy',
    },
    'saved-vehicles': {
      title: 'Favorite Vehicles | CarStory',
      description: 'Favorite Vehicles | CarStory',
      canonical: 'https://www.carstory.com//saved-vehicles',
    },
    'terms-of-service': {
      title: 'Terms of Service | CarStory',
      description: 'Terms of Service | CarStory',
      canonical: 'https://www.carstory.com/tos',
    },
  }

  const routeName = computed(() => useRouter().currentRoute.value.name)

  watchEffect(() => {
    const name = routeName.value as keyof typeof meta
    if (meta[name]) {
      import.meta.server ? useSeoMeta(meta[name]) : nextTick(() => useSeoMeta(meta[name]))
    }
  })
}
